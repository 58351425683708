import React from "react";
import { vars } from "../../../css/vars.css";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1000.84 187.85" {...props}>
      <g>
        <g>
          <path
            fill={vars.colors.contrast}
            d="M264.96,61.24h43.14v12.05h-29.14V88.6h23.91v11.49h-23.91v26.52h-14.01V61.24z"
          />
          <path
            fill={vars.colors.contrast}
            d="M342.38,61.24c4.73,0,8.92,0.89,12.56,2.66c3.64,1.77,6.46,4.31,8.45,7.61c1.99,3.3,2.99,7.13,2.99,11.49
            c0,4.17-1,7.86-2.99,11.07c-1.99,3.21-4.73,5.65-8.22,7.33l12.89,25.21h-15.5l-10.93-21.95h-11.86v21.95h-14.01V61.24H342.38z
            M329.77,92.99h11.49c3.3,0,5.93-0.9,7.89-2.71c1.96-1.8,2.94-4.23,2.94-7.28c0-3.11-0.98-5.57-2.94-7.38
            c-1.96-1.81-4.59-2.71-7.89-2.71h-11.49V92.99z"
          />
          <path
            fill={vars.colors.contrast}
            d="M370.02,126.61l23.91-65.37h16.81l23.72,65.37h-15.03l-5.04-14.29h-24.84l-5.04,14.29H370.02z M393.27,101.03h17.28
            l-8.69-24.84L393.27,101.03z"
          />
          <path
            fill={vars.colors.contrast}
            d="M457.62,93.27l-20.36-32.03h17l12.89,20.55l13.17-20.55h15.32l-20.36,32.03l21.39,33.34h-17.09l-13.91-21.95
            l-14.19,21.95h-15.22L457.62,93.27z"
          />
          <path
            fill={vars.colors.contrast}
            d="M500.34,76.42c2.58-5.01,6.29-8.89,11.11-11.63c4.82-2.74,10.51-4.11,17.04-4.11c5.1,0,9.68,1,13.73,2.99
            c4.05,1.99,7.31,4.8,9.81,8.41c2.49,3.61,3.98,7.78,4.48,12.51H542.5c-0.81-3.74-2.51-6.68-5.09-8.82
            c-2.58-2.15-5.81-3.22-9.67-3.22c-5.23,0-9.37,1.96-12.42,5.88c-3.05,3.92-4.58,9.09-4.58,15.5c0,6.35,1.52,11.5,4.58,15.46
            c3.05,3.95,7.19,5.93,12.42,5.93c3.86,0,7.08-1.07,9.67-3.22c2.58-2.15,4.28-5.09,5.09-8.82h14.01c-0.5,4.73-1.99,8.9-4.48,12.51
            c-2.49,3.61-5.76,6.41-9.81,8.4c-4.05,1.99-8.62,2.99-13.73,2.99c-6.54,0-12.22-1.37-17.04-4.11
            c-4.83-2.74-8.53-6.61-11.11-11.63c-2.58-5.01-3.88-10.85-3.88-17.51C496.46,87.27,497.76,81.43,500.34,76.42z"
          />
          <path
            fill={vars.colors.contrast}
            d="M557.07,126.61l23.91-65.37h16.81l23.72,65.37h-15.03l-5.04-14.29h-24.84l-5.04,14.29H557.07z M580.32,101.03h17.28
            l-8.69-24.84L580.32,101.03z"
          />
          <path
            fill={vars.colors.contrast}
            d="M627.48,61.34h14.01v29.32l26.15-29.32h17l-24,26.9l25.59,38.38h-16.81l-18.68-27.55l-9.24,10.37v17.18h-14.01V61.34z"
          />
          <path
            fill={vars.colors.contrast}
            d="M691.27,61.24h43.89v12.05h-29.88v14.66h24.28v11.49h-24.28v15.13h30.91v12.05h-44.92V61.24z"
          />
          <path
            fill={vars.colors.contrast}
            d="M755.7,105.32c0.25,3.24,1.57,5.84,3.97,7.8c2.4,1.96,5.59,2.94,9.57,2.94c3.3,0,5.99-0.7,8.08-2.1
            c2.08-1.4,3.13-3.35,3.13-5.84c0-1.74-0.58-3.13-1.73-4.16c-1.15-1.03-2.77-1.84-4.86-2.43c-2.09-0.59-5.31-1.32-9.67-2.2
            c-4.3-0.81-7.91-1.85-10.83-3.13c-2.93-1.28-5.31-3.18-7.14-5.7c-1.84-2.52-2.75-5.87-2.75-10.04c0-3.8,1.04-7.19,3.13-10.18
            c2.08-2.99,4.98-5.34,8.68-7.05c3.7-1.71,7.89-2.57,12.56-2.57c4.79,0,9.11,0.89,12.93,2.66c3.83,1.77,6.85,4.22,9.06,7.33
            c2.21,3.11,3.35,6.63,3.41,10.55h-13.73c-0.31-2.8-1.49-5.06-3.55-6.77c-2.05-1.71-4.76-2.57-8.12-2.57
            c-3.11,0-5.6,0.65-7.47,1.96c-1.87,1.31-2.8,3.17-2.8,5.6c0,1.74,0.56,3.1,1.68,4.06c1.12,0.97,2.71,1.74,4.76,2.33
            c2.05,0.59,5.23,1.29,9.53,2.1c4.36,0.81,8.03,1.82,11.02,3.03c2.99,1.21,5.4,3.05,7.24,5.51c1.84,2.46,2.75,5.74,2.75,9.85
            c0,3.98-1.09,7.55-3.27,10.69c-2.18,3.14-5.21,5.62-9.11,7.42c-3.89,1.81-8.26,2.71-13.12,2.71c-5.23,0-9.88-0.95-13.96-2.85
            c-4.08-1.9-7.27-4.53-9.57-7.89c-2.3-3.36-3.49-7.13-3.55-11.3L755.7,105.32z"
          />
          <path
            fill={vars.colors.contrast}
            d="M795.67,61.24l14.47-0.09l12.05,47.81l12.79-47.72h12.61l12.42,47.72l12.33-47.72h14.2l-18.12,65.37h-16.16l-11.3-43.42
            l-11.02,43.42h-16.16L795.67,61.24z"
          />
          <path
            fill={vars.colors.contrast}
            d="M880.18,126.61l23.91-65.37h16.81l23.72,65.37h-15.03l-5.04-14.29H899.7l-5.04,14.29H880.18z M903.44,101.03h17.28
            l-8.69-24.84L903.44,101.03z"
          />
          <path
            fill={vars.colors.contrast}
            d="M977.21,61.24c4.67,0,8.79,0.89,12.37,2.66c3.58,1.77,6.35,4.31,8.31,7.61c1.96,3.3,2.94,7.13,2.94,11.49
            c0,4.36-0.98,8.17-2.94,11.44c-1.96,3.27-4.73,5.79-8.31,7.56c-3.58,1.77-7.7,2.66-12.37,2.66H964.6v21.95H950.6V61.24H977.21z
            M964.6,92.99h11.3c3.3,0,5.91-0.9,7.84-2.71c1.93-1.8,2.89-4.23,2.89-7.28c0-3.11-0.97-5.57-2.89-7.38
            c-1.93-1.81-4.55-2.71-7.84-2.71h-11.3V92.99z"
          />
        </g>
      </g>

      <g>
        <path
          fill={vars.colors.contrast}
          d="M180.91,109.17c0-10.9-3.19-19.82-7.77-26.99l25.48-25.48l-25.66-25.68l-27.38,27.38c1.13-3.46,2.49-7.84,3.72-12.36
			c1.75-6.44,3.46-13.99,3.46-19.52c0-6.55-1.44-13.13-5.33-18.24C143.33,2.89,137.14,0,129.7,0c-5.82,0-10.75,2.14-14.62,5.82
			c-3.7,3.52-6.15,8.2-7.85,13.07c-2.98,8.57-4.15,19.33-4.47,30.07h-7.13c-0.33-10.74-1.49-21.5-4.47-30.07
			C89.46,14.02,87,9.34,83.3,5.82C79.44,2.14,74.5,0,68.68,0c-7.44,0-13.63,2.89-17.74,8.29c-3.89,5.11-5.33,11.69-5.33,18.24
			c0,5.53,1.72,13.08,3.46,19.52c1.19,4.36,2.49,8.59,3.6,11.99L25.67,31.03L0,56.7l25.34,25.34c-4.63,7.19-7.87,16.16-7.87,27.13
			c0,10.17,2.6,19.65,7.59,27.95L0,162.18l25.66,25.68l27.04-27.05c12.62,5.77,28.24,8.91,46.42,8.92h0.13
			c18.26-0.01,33.93-3.17,46.58-9l27.12,27.12l25.67-25.67l-25.21-25.21C178.35,128.71,180.91,119.29,180.91,109.17z M59.14,63.31
			c1.54-0.61,2.39-2.33,1.85-3.89c-2.39-6.97-8.22-24.87-8.22-33.81c0-11.25,5.02-19.38,15.92-19.38c15.69,0,19.6,22.23,19.88,46.08
			c0.02,1.59,1.31,2.88,2.9,2.88h15.46c1.59,0,2.88-1.29,2.9-2.88c0.28-23.85,4.19-46.08,19.88-46.08c10.9,0,15.92,8.13,15.92,19.38
			c0,8.94-5.83,26.84-8.22,33.81c-0.54,1.57,0.31,3.28,1.85,3.89c8.74,3.44,34.52,16.06,34.52,44.94c0,0.95-0.03,1.9-0.08,2.84
			c-2.86,23.23-40.29,40.82-74.44,40.82c-35.47,0-74.51-18.97-74.63-43.54c0-0.04,0-0.08,0-0.11C24.62,79.37,50.4,66.75,59.14,63.31
			z"
        />
        <path
          fill={vars.colors.contrast}
          d="M69.23,111.13c4.73,0,8.56-4.22,8.56-12.33c0-8.11-3.83-12.33-8.56-12.33c-4.73,0-8.56,4.22-8.56,12.33
			C60.68,106.91,64.51,111.13,69.23,111.13z"
        />
        <path
          fill={vars.colors.contrast}
          d="M124.86,111.13c4.73,0,8.56-4.22,8.56-12.33c0-8.11-3.83-12.33-8.56-12.33c-4.73,0-8.56,4.22-8.56,12.33
			C116.3,106.91,120.13,111.13,124.86,111.13z"
        />
      </g>
    </Svg>
  );
};

export default Logo;
